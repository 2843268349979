import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { DateCalendar as Calendar } from '@mui/x-date-pickers/DateCalendar';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { ReactElement, useCallback } from 'react';
import { COLORS } from '../../../theme/variables';
import { TestId } from '../../Testing/TestId';
import { Text } from '../../common';
import { DialogTitle } from '../../common/dialog';

export interface Props {
  open: boolean;
  onClose: () => void;
  shouldDisableDate: (date: Date) => boolean;
  onChange: (d: Date) => void;
  onAvailability: () => void;
  value: Date;
  min: Date;
  max: Date;
}

const useStyles = makeStyles({
  content: {
    paddingBottom: '32px',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 8,

    '& .MuiSvgIcon-root': {
      fontSize: '14px',
    },
  },
  title: {
    textAlign: 'center',

    '& h4': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '15px',
    },
    '& button[aria-label=close] .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
  link: {
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export function Day({
  open,
  onClose,
  shouldDisableDate,
  onChange,
  onAvailability,
  min,
  max,
  value,
}: Props): ReactElement {
  const classes = useStyles();
  const handleDisableDate = useCallback(
    (d: Date | null) => (d ? shouldDisableDate(d) : false),
    [shouldDisableDate],
  );
  const handleChange = useCallback(
    (d: Date | null, selectionState?: PickerSelectionState | undefined) =>
      d && selectionState === 'finish' && onChange(d),
    [onChange],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle onClose={onClose} className={classes.title}>
        Choose Meeting Date
      </DialogTitle>
      <DialogContent className={classes.content} data-testid='calendar-element'>
        <TestId testId='calendar-element'>
          <Calendar
            value={value}
            minDate={min}
            maxDate={max}
            onChange={handleChange}
            shouldDisableDate={handleDisableDate}
          />
        </TestId>
        <Text
          onClick={onAvailability}
          variant={'normal'}
          color={COLORS.COLOR_BLUE_BASE}
          className={classes.link}>
          Or provide your availability instead
        </Text>
      </DialogContent>
    </Dialog>
  );
}
