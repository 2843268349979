import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps, useForm } from 'react-final-form';
import { TenantDetails } from '../../api/auth';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import { Email, isEmail } from '../../utils/String/Email';
import { fromEmails, toEmails } from '../../utils/String/EmailsList';
import { isUrl, Url } from '../../utils/String/Url';
import { lengthField } from '../../utils/form';
import {
  checkSizeTablet,
  getAbbreviationByName,
  isMobile,
  validateEmail,
} from '../../utils/functions';
import { TestId } from '../Testing/TestId';
import {
  BeforeUnload,
  Button,
  FormGroup,
  StickyContent,
  UserLogo,
} from '../common';
import { EmailList } from '../common/EmailList';
import { FormEmail, FormProgramName, TextFieldWrapper } from './wrappers';

interface MentorDetailsFormProps {
  tenantDetails: TenantDetails;
  loading?: boolean;
  onSubmit: (parsedFormValues: ParsedFormValues) => any;
}

interface FormValues {
  tenantName: string;
  programName: string;
  description: string;
  parentName: string;
  websiteUrl: string;
  country: string;
  state: string;
  city: string;
  email: string;
  phone: string;
  managerFirstName: string;
  managerLastName: string;
  logo: string;
  timeZone: TenantTimeZone;
  defaultPhysicalLocation: string;
  additionalEmails: (Email | string)[];
}

export interface ParsedFormValues {
  values: TenantDetails;
  logoFile: File | null;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const useStyles = makeStyles((theme) => ({
  sectionBlock: {
    '& + &': {
      marginTop: 56,
    },
  },
  sectionTitle: {
    marginBottom: 32,
  },
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  logoFormBlock: {
    margin: '0 0 40px 0',

    [theme.breakpoints.up('xs')]: {
      margin: '65px 0 0 30px',
    },

    [theme.breakpoints.up(1000)]: {
      marginLeft: 80,
    },
  },
  mainFormBlock: {
    width: 560,
  },
  actionsBlock: {
    marginTop: 56,
  },
  descriptionField: {
    minHeight: 64,
  },
}));

const validateForm = (values: FormValues) => {
  const errors: Errors = {};
  if (!values.tenantName) {
    errors.tenantName = 'Required';
  }
  if (!values.programName) {
    errors.programName = 'Required';
  }
  if (!values.managerFirstName) {
    errors.managerFirstName = 'Required';
  }
  if (!values.managerLastName) {
    errors.managerLastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Invalid';
  }

  if (values.websiteUrl && !isUrl(values.websiteUrl)) {
    errors.websiteUrl = 'Invalid';
  }

  if (values.additionalEmails && !values.additionalEmails.every(isEmail)) {
    errors.additionalEmails = 'Invalid';
  }

  return errors;
};

function getInitialValues(tenantDetails: TenantDetails): FormValues {
  return {
    tenantName: tenantDetails.tenantName || '',
    programName: tenantDetails.programName || '',
    description: tenantDetails.description || '',
    parentName: tenantDetails.parentName || '',
    country: tenantDetails.country || '',
    state: tenantDetails.state || '',
    city: tenantDetails.city || '',
    email: tenantDetails.email,
    phone: tenantDetails.phone || '',
    managerFirstName: tenantDetails.managerFirstName || '',
    managerLastName: tenantDetails.managerLastName || '',
    defaultPhysicalLocation: tenantDetails.defaultPhysicalLocation || '',
    logo: tenantDetails.logo || '',
    timeZone: tenantDetails.timeZone,
    websiteUrl: tenantDetails.websiteUrl || '',
    additionalEmails: tenantDetails.additionalEmails
      ? toEmails(tenantDetails.additionalEmails)
      : ([] as Email[]),
  };
}

function getParsedValues(
  formValues: FormValues,
  logoFile: File | null,
): ParsedFormValues {
  return {
    values: {
      tenantName: formValues.tenantName,
      programName: formValues.programName,
      description: formValues.description || '',
      parentName: formValues.parentName || '',
      country: formValues.country || '',
      state: formValues.state || '',
      city: formValues.city || '',
      email: formValues.email,
      phone: formValues.phone || '',
      managerFirstName: formValues.managerFirstName,
      managerLastName: formValues.managerLastName,
      logo: formValues.logo || '',
      timeZone: formValues.timeZone,
      defaultPhysicalLocation: formValues.defaultPhysicalLocation || null,
      websiteUrl: (formValues.websiteUrl as Url) || null,
      additionalEmails: fromEmails(formValues.additionalEmails as Email[]),
    },
    logoFile,
  };
}

function TenantProfileForm({
  tenantDetails,
  loading = false,
  onSubmit,
}: MentorDetailsFormProps) {
  const classes = useStyles();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [fieldsValidation, setFieldsValidation] = useState<{
    name: boolean;
    email: boolean;
  }>({ name: true, email: true });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (formValues: FormValues) => {
    const parsedFormValues = getParsedValues(formValues, logoFile);
    return onSubmit(parsedFormValues);
  };

  const initialValues = useMemo(
    () => getInitialValues(tenantDetails),
    [tenantDetails],
  );

  const validateLogoFile = (logo: File) => {
    const fileName = logo.name;
    if (fileName.length > lengthField.logoFileName) {
      enqueueSnackbar('The maximum length of the file name is 75 characters', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  const handleFieldsValidation = useCallback(
    (field: 'email' | 'name') => (valid: boolean) => {
      setFieldsValidation(
        (prevValidations) =>
          ({
            ...(prevValidations ? prevValidations : {}),
            [field]: valid,
          } as { name: boolean; email: boolean }),
      );
    },
    [],
  );

  return (
    <div>
      <Form
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={(formProps) => (
          <FormWrapper formProps={formProps}>
            <BeforeUnload
              when={formProps.dirty && !loading}
              title='Leave the page'
              body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
              disabled={loading}
              confirmButtonRenderer={({ onConfirm }) => (
                <Button
                  variant='outlined'
                  onClick={async () => {
                    await formProps.handleSubmit();
                    onConfirm();
                  }}
                  disabled={
                    loading ||
                    !formProps.valid ||
                    !fieldsValidation?.email ||
                    !fieldsValidation?.name
                  }>
                  {loading ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Save the changes'
                  )}
                </Button>
              )}
            />
            <div className={classes.formBlocks}>
              <div className={classes.mainFormBlock}>
                <form noValidate>
                  <div className={classes.sectionBlock}>
                    <Typography
                      className={classes.sectionTitle}
                      variant='h3'
                      data-testid='tenant-profile-title-main-info'>
                      Main info
                    </Typography>
                    {isMobile() && (
                      <div className={classes.logoFormBlock}>
                        <Field<string>
                          name='logo'
                          parse={(value) => (!value ? '' : value)}
                          component={({ input }) => (
                            <UserLogo
                              logo={input.value}
                              name={getAbbreviationByName(
                                formProps.values.programName || '',
                                (formProps.values.programName || '').split(
                                  ' ',
                                )[1] || '',
                              )}
                              onUpload={(photoFile) => {
                                if (validateLogoFile(photoFile)) {
                                  setLogoFile(photoFile);
                                  const blobFile =
                                    URL.createObjectURL(photoFile);
                                  input.onChange(blobFile);
                                }
                              }}
                              onRemove={() => {
                                setLogoFile(null);
                                input.onChange('');
                              }}
                              withoutDownload
                            />
                          )}
                        />
                      </div>
                    )}
                    <FormGroup mobile={checkSizeTablet(800)}>
                      <Field<string>
                        name='tenantName'
                        label='Short Program Name*'
                        component={FormProgramName}
                        data-testid='user-profile-tenant-name'
                        fieldType='short'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.programName,
                          },
                        }}
                      />
                      <Field<string>
                        name='programName'
                        label='Program Name*'
                        component={FormProgramName}
                        data-testid='user-profile-program-name'
                        fieldType='long'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.programName,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='description'
                        component={TextFieldWrapper}
                        testid='user-profile-description'
                        label='Description'
                        multiline
                        InputProps={{
                          inputProps: {
                            className: classes.descriptionField,
                            maxLength: lengthField.tenantDescription,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='parentName'
                        label='Parent Organization Name'
                        component={TextFieldWrapper}
                        testid='user-profile-parent-name'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.name,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='websiteUrl'
                        label='Website URL'
                        component={TextFieldWrapper}
                        testid='user-profile-website-url'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.url,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='country'
                        label='Country'
                        component={TextFieldWrapper}
                        testid='user-profile-country'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.country,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='state'
                        label='State'
                        component={TextFieldWrapper}
                        testid='user-profile-state'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.state,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='city'
                        label='City'
                        component={TextFieldWrapper}
                        testid='user-profile-city'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.city,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='defaultPhysicalLocation'
                        label='Default in-person location you can change'
                        component={TextFieldWrapper}
                        testid='user-profile-defaultPhysicalLocation'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.location,
                          },
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className={classes.sectionBlock}>
                    <Typography
                      className={classes.sectionTitle}
                      variant='h3'
                      data-testid='tenant-profile-title-manager-info'>
                      Manager Info
                    </Typography>
                    <FormGroup mobile={checkSizeTablet(800)}>
                      <Field<string>
                        name='managerFirstName'
                        label='Manager First Name*'
                        component={TextFieldWrapper}
                        testid='user-profile-manager-first-name'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.firstName,
                          },
                        }}
                      />
                      <Field<string>
                        name='managerLastName'
                        label='Manager Last Name*'
                        component={TextFieldWrapper}
                        testid='user-profile-manager-last-name'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.lastName,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='phone'
                        label='Phone'
                        component={TextFieldWrapper}
                        testid='user-profile-phone'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.tenantPhone,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='email'
                        component={FormEmail}
                        data-testid='user-profile-email'
                        label='Email*'
                        formatOnBlur
                        format={(value: string) => {
                          return value ? value.toLowerCase() : value;
                        }}
                        entityType='tenant'
                        onValid={handleFieldsValidation('email')}
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.email,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<Email[]>
                        name='additionalEmails'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.email,
                          },
                        }}
                        render={(props) => (
                          <TestId testId='user-profile-additional-emails'>
                            <EmailList
                              value={props.input.value}
                              onChange={props.input.onChange}
                              error={props.meta.error}
                              label='Additional Emails'
                            />
                          </TestId>
                        )}
                      />
                    </FormGroup>
                  </div>
                  <div className={classes.actionsBlock}>
                    <StickyContent>
                      <TestId testId='submit-button'>
                        <Button
                          onClick={formProps.handleSubmit}
                          disabled={
                            loading ||
                            !formProps.valid ||
                            !fieldsValidation?.email ||
                            !fieldsValidation?.name
                          }
                          startIcon={<CheckIcon />}>
                          {loading ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </TestId>
                    </StickyContent>
                  </div>
                </form>
              </div>
              {!isMobile() && (
                <div className={classes.logoFormBlock}>
                  <Field<string>
                    name='logo'
                    parse={(value) => (!value ? '' : value)}
                    component={({ input }) => (
                      <UserLogo
                        logo={input.value}
                        name={getAbbreviationByName(
                          formProps.values.programName || '',
                          (formProps.values.programName || '').split(' ')[1] ||
                            '',
                        )}
                        onUpload={(photoFile) => {
                          if (validateLogoFile(photoFile)) {
                            setLogoFile(photoFile);
                            const blobFile = URL.createObjectURL(photoFile);
                            input.onChange(blobFile);
                          }
                        }}
                        onRemove={() => {
                          setLogoFile(null);
                          input.onChange('');
                        }}
                        withoutDownload
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </FormWrapper>
        )}
      />
    </div>
  );
}

function FormWrapper({
  formProps,
  children,
}: {
  formProps: FormRenderProps<FormValues, FormValues>;
  children: any;
}) {
  const formAPI = useForm();

  useEffect(() => {
    formAPI.change('logo', formProps.initialValues.logo);
  }, [formAPI, formProps.initialValues.logo]);

  return children;
}

export default TenantProfileForm;
